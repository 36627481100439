<template>
  <b-card-actions
    ref="meetingActionCard"
    title="Meeting Calendar"
    action-refresh
    action-collapse
    @refresh="loadLeads"
  >
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card-actions>

</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fullcalendar/core/vdom' // solves problem with Vite
// eslint-disable-next-line import/extensions
import BCardActions from '@core/components/b-card-actions/BCardActions'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Calendar',
  components: {
    FullCalendar,
    BCardActions,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'title',
          end: 'prev,next,dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        events: [
          { title: 'event 1', date: '2021-04-01 18:00:00' },
          { title: 'event 2', date: '2021-04-02' },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      leadsList: 'users/leadsList',
    }),
  },
  async created() {
    await this.loadLeads()
  },
  methods: {
    ...mapActions({
      loadAllLeads: 'get',
    }),
    async loadLeads() {
      await this.loadAllLeads({
        storeName: 'users',
        url: '/admin/users/leads',
        commitUrl: 'users/SET_LEADS',
      }).then(() => {
        // eslint-disable-next-line array-callback-return
        this.leadsList.map(value => {
          if (value.lead.statusGroupID === 2 && value.lead.follow_up_time !== null) {
            this.calendarOptions.events.push({ title: value.name, date: `${value.lead.follow_up_date} ${value.lead.follow_up_time}` })
          }
        })
      })
      this.$refs.leadsListActionCard.showLoading = false
    },
  },
}
</script>

<style scoped>

</style>
